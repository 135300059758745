import React, {useState, useEffect} from "react";
import "./student.css";
import {Link} from "react-router-dom";

import MainSidebar from "../../components/Header/index";
import Header from "../../components/Header/index";

import user from "../../images/user-info.jpg";

const Students = () => {
    const token = localStorage.getItem("token")
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(0);
    const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");

    useEffect(() => {
        fetch("http://api.edupulse.software/api/v1/admin/students?limit=100", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        `Network response was not ok. Status: ${response.status}`
                    );
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
            })
            .catch((error) => {
                setError(error);
            });
    }, []);

    const postData = async (e) => {
        e.preventDefault(); // Prevent default form submission

        try {
            const response = await fetch(
                "http://api.edupulse.software/api/v1/admin/students",
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name: name,
                        phone_number: phoneNumber,
                        secondary_phone_number: secondaryPhoneNumber,
                        subject_titles: [],
                        assigned_groups: [{id: null, title: "no groups"}],
                    }),
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log("Response:", responseData);

            // Optionally, you can update state or perform other actions after successful submission
            // For example, fetch updated data
            // fetchData();
        } catch (error) {
            console.error("Error:", error.message);
        }
    };

    if (error) {
        console.log("Error:", error.message);
    }

    return (
        <>
            {/*<Header/>*/}
            {/*<MainSidebar/>*/}
            <div className="students_hehe">
                <div className="student_inputs hehe">
                    <form onSubmit={postData}>
                        <div className="student_params_div">
                            <div>
                                <p>Name</p>
                                <input
                                    value={name}
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Name:"
                                />
                            </div>
                            <div>
                                <p>Phone number</p>
                                <input
                                    value={phoneNumber}
                                    type="number"
                                    onChange={(e) => Number(setPhoneNumber(e.target.value))}
                                    placeholder="Phone number:"
                                />
                            </div>
                            <div>
                                <p>Secondary Phone number</p>
                                <input
                                    value={secondaryPhoneNumber}
                                    type="number"
                                    onChange={(e) =>
                                        Number(setSecondaryPhoneNumber(e.target.value))
                                    }
                                    placeholder="Secondary Phone number:"
                                />
                            </div>
                            <div>
                                <button type="submit" className="rainbow-hover upd-btn">
                                    Post
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="table_map">
                    {data.data &&
                        data.data.map((student) => (
                            <Link to={`/students/${student.id}`} key={student.id}>
                                <tr id="tr">
                                    <td id="td">
                                        <img
                                            src={user}
                                            width="48"
                                            height="48"
                                            class="bg-light rounded-circle me-2"
                                            alt="Avatar"
                                        />
                                        {student.name}
                                    </td>
                                    <td class="text-fade" id="td2">
                                        +{student.phone_number}
                                    </td>
                                </tr>
                            </Link>
                        ))}
                </div>
            </div>
        </>
    );
};

export default Students;
