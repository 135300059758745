import React, { useEffect, useState } from 'react';
import RedirectButton from '../../../components/PaymentTable/RedirectButton';
import './Button_styling.css';

const token = localStorage.getItem("token")

const PaymentTable = ({ id, title, fee, days_in_week, start_time, end_time, start_date, teacher_name, subject, students }) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await fetch(`http://api.edupulse.software/api/v1/recipient/payment/months?group_id=${id}`, {
                    method: 'GET',
                    headers: headers
                });
                if (!response.ok) {
                    throw new Error(`Token has expired or is invalid. Status ${response.status}`);
                }
                const result = await response.json();
                setData(result);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    if (loading) {
        return <tr><td colSpan="12">Loading...</td></tr>;
    }

    if (error) {
        return <tr><td colSpan="12">Error: {error.message}</td></tr>;
    }

    return (
        <tr key={id}>
            <td>{title}</td>
            <td>{fee}</td>
            <td>{days_in_week.join(', ')}</td>
            <td>{start_time}</td>
            <td>{end_time}</td>
            <td>{new Date(start_date).toLocaleDateString()}</td>
            <td>{data.data.duration}</td>
            <td>{data.data.months.join(', ')}</td>
            <td>{teacher_name}</td>
            <td>{subject}</td>
            <td>{students}</td>
            <td><RedirectButton id={id} title={'More info'} path={'/recipient/payment/showstudents'}/></td>
        </tr>
    );
};

export default PaymentTable;
