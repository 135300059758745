import React from 'react';
import LoginForm from '../../components/logIn';
import '../../css/skin_color.css';
import '../../css/style_rtl.css';
import '../../css/style.css';
import '../../css/vendors_css.css'
import '../../css/color_theme.css'
import '../../css/login.css'

function LoginPage() {
  return (
    <>
      
    <LoginForm />
    </>
  );
}

export default LoginPage;
