import React, { useState, useEffect } from 'react';
import img from '../../../images/avatar/avatar-1.png'
import '../../../css/admin/teachers.css'
import { token_1 } from '../Subjects/Subjects';
import { GetSubjects } from '../Subjects/Subjects';

const url = 'http://api.edupulse.software/api/v1/admin/teachers?limit=100'

const CreateTeacher = () => {
	// State for controlling the popup visibility
	const [isOpen, setIsOpen] = useState(false);
	
	const subjects = GetSubjects().data
	// State for form data
	const [formData, setFormData] = useState({
	  name: '',
	  position: '',
	  is_payment_receivable: false,
	  selectedSubjectsId: []
	});
  
	// Function to toggle popup visibility
	const togglePopup = () => {
	  setIsOpen(!isOpen);
	};
  
	// Function to handle form submission
	const postData = async (formData) => {
	  try {
		const headers = {
		  Authorization: `Bearer ${token_1}`,
		  'Content-Type': 'application/json',
		};
  
		const response = await fetch(url, {
		  method: 'POST',
		  headers,
		  body: JSON.stringify(formData)
		});
  
		if (!response.ok) {
		  throw new Error('Failed to add teacher');
		  window.location.reload()
		}
  
		window.location.reload(); // Reload the page after successful submission
  
	  } catch (error) {
		console.error('Error:', error);
		// Handle error state or display error message to user
		window.location.reload()
	  }
	};
  
	// Function to handle input changes
	const handleChange = (event) => {
	  const { name, value, type, checked, options } = event.target;
  
	  if (type === 'checkbox') {
		setFormData({ ...formData, [name]: checked});
	  } else if (type === 'select-multiple') {
		const selectedOptions = Array.from(options).filter(option => option.selected).map(option => Number(option.value));
		setFormData({ ...formData, selectedSubjectsId: selectedOptions });
	  } else {
		setFormData({ ...formData, [name]: value });
	  }
	};
  
	// Function to handle form submission
	const handleSubmit = (event) => {
	  event.preventDefault();
	  console.log(JSON.stringify(formData));
	  postData(formData);
	};
  
	return (
	  <div className="box-controls pull-right d-md-flex d-none">
		<button onClick={togglePopup} className="waves-effect waves-light w-p100 btn btn-primary">
		  <i className="fa fa-plus me-15"></i> Add New Teacher
		</button>
		{isOpen && (
		  <div className="popup rounded10">
			<div className="box">
			  <div className="box-header with-border">
				<h4 className="box-title">Form Sections</h4>
			  </div>
			  <form onSubmit={handleSubmit}>
				<div className="box-body">
				  <h4 className="mt-0 mb-20">Teacher Info:</h4>
				  <div className="form-group">
					<label className="form-label">Full Name:</label>
					<input
					  className="form-control"
					  onChange={handleChange}
					  placeholder="Enter full name"
					  name="name"
					  value={formData.name}
					/>
				  </div>
				  <div className="form-group">
					<label className="form-label">Position:</label>
					<input
					  className="form-control"
					  onChange={handleChange}
					  placeholder="Enter Position"
					  name="position"
					  value={formData.position}
					/>
				  </div>
				  <hr />
				  <div className="form-group">
					<div className="c-inputs-stacked">
					  <input
						onChange={handleChange}
						name="is_payment_receivable"
						type="checkbox"
						id="checkbox_123"
						checked={formData.is_payment_receivable}
					  />
					  <label htmlFor="checkbox_123" className="me-30">Payment Receivable</label>
					</div>
				  </div>
				  <hr />
				  <div className="form-group">
					<label className="form-label">Select Multiple</label>
					<select
                    multiple
                    onChange={handleChange}
                    className="form-select"
                    value={formData.options}
                    name="options">
                    	{subjects.data.map((subject, index) => (<option value={subject.id}>{subject.title}</option>))}
                  </select>
				  </div>
				</div>
				<div className="box-footer">
				  <button type="button" className="btn btn-danger" onClick={togglePopup}>Cancel</button>
				  <button type="submit" className="btn btn-success pull-right">Submit</button>
				</div>
			  </form>
			</div>
		  </div>
		)}
	  </div>
	);
}

const Teachers = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${token_1}`,
          'Content-Type': 'application/json',
        };

        const response = await fetch(url, {
          headers,
        });

        if (!response.ok) {
          throw new Error('Token is esxperied');
        }

        const jsonData = await response.json();
        setData(jsonData);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
	<div class="row container mx-auto">

	<div class="box bg-transparent no-shadow mb-30">
		<div class="box-header no-border pb-0">
		<h1 class="box-title">All Teachers</h1>
			<CreateTeacher/>
		</div>
	</div>
	<hr />
	{data.data.map((teacher, index) => (
		<div class="box mb-15 pull-up">
			<div class="box-body">
				<div class="d-flex align-items-center justify-content-between">
					<div class="d-flex align-items-center" style={{fontSize:'large'}}>
						<div class="me-15 mb-1">
							<img src={img} class="bg-primary-light avatar avatar-lg rounded-circle" alt=""/>
						</div>
						<div class="d-flex flex-column fw-500">
							<a href="extra_profile.html" class="text-dark hover-primary mb-1">{teacher.name}</a>
							<span class="text-fade">{teacher.position}</span>
						</div>
					</div>
					<div class="d-flex align-items-center">
					<a href={'teachers/'+teacher.id} class="waves-effect waves-light btn btn-sm btn-secondary br-5" style={{fontSize:'large'}}> <i className='fa fa-edit me-10'></i> Edit</a>
						</div>
				</div>
			</div>
		</div>
        ))}
</div>
  );
}


export default Teachers