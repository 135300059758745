import React from 'react'
import MonthInfo from '../../components/finance/monthInfo'

const MonthInfoPage = () => {
  return (
    <MonthInfo
    month="January"
    totalAmount="1000"
    totalPaid="800"
    totalStudents="50"
  />
    
  )
}

export default MonthInfoPage