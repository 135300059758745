import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalizeWords, formatTimeRange, formatUzbekSum, getDayAbbreviation, getFirstLetter } from '../../functions';

const RelatedGroups = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const token = localStorage.getItem("token")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };

                const response = await fetch('http://api.edupulse.software/api/v1/admin/group-student/show-groups?subject_id=2', { headers });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const jsonData = await response.json();
                setData(jsonData.data.groups);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [token]);

    const handleNavigate = (groupId) => {
        navigate(`/groups/${groupId}`);
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className="col-12">
            <div className="box no-shadow mb-0 bg-transparent">
                <div className="box-header no-border px-0">
                    <h3 className="fw-500 box-title">Related Groups</h3>
                    <div className="box-controls pull-right d-md-flex d-none">
                        <a href="course.html">All Groups</a>
                    </div>
                </div>
            </div>
            <div>
                {data.map((group, index) => (
                    <div key={group.id} className="box mb-15 pull-up">
                        <div className="box-body">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="w-40 d-flex align-items-center">
                                    <div className={`me-15 ${index % 4 === 1 ? 'bg-warning' : index % 4 === 2 ? 'bg-danger' : index % 4 === 3 ? 'bg-success' : 'bg-primary'} h-50 w-65 l-h-55 rounded text-center`}>
                                        <span className="fs-24">{getFirstLetter(capitalizeWords(group.title))}</span>
                                    </div>
                                    <div className='w-100 d-flex justify-content-between'>
                                        <div className="d-flex flex-column fw-500">
                                            <a href="course.html" className="text-dark hover-warning mb-1 fs-16">{capitalizeWords(group.title)}</a>
                                            <span className="text-fade">{formatUzbekSum(group.fee)}</span>
                                        </div>
                                        <div className="d-flex flex-column fw-500">
                                            <a href="course.html" className="text-dark hover-warning mb-1 fs-16">
                                                {group.days_in_week.map((day, index) => (
                                                    <span key={index}>{getDayAbbreviation(day)}, </span>
                                                ))}
                                            </a>
                                            <span className="text-fade day-abbreviation">
                                                {formatTimeRange(group.start_time, group.end_time)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <button className={`waves-effect waves-light btn btn-sm ${index % 4 === 1 ? 'btn-warning-light' : index % 4 === 2 ? 'btn-danger-light' : index % 4 === 3 ? 'bg-success-light' : 'bg-primary-light'} me-10`} onClick={() => handleNavigate(group.id)}>View Group</button>
                                    <div className="dropdown">
                                        <a className="px-10 pt-5" href="#" data-bs-toggle="dropdown"><i className="fa fa-ellipsis-v"></i></a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <a className="dropdown-item flexbox" href="#">Apply</a>
                                            <a className="dropdown-item flexbox" href="#">Make a Payment</a>
                                            <a className="dropdown-item flexbox" href="#">Benefits</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RelatedGroups;
