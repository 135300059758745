// src/services/apiService.js
import axios from 'axios';

const apiUrl = 'http://api.edupulse.software/api/v1'; // Replace with your API base URL

const apiService = axios.create({
    baseURL: apiUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default apiService;
