import React, { useEffect, useState } from 'react';
import PaymentTable from "./ShowGroupsMonth"
import './Button_styling.css';
import './App.css';

const token = localStorage.getItem("token")

function ShowGroups() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [id, setId] = useState('')
  
  useEffect(() => {
    fetch('http://api.edupulse.software/api/v1/recipient/payment/',{
        headers: {
          'Authorization': `Bearer ${token}`, // Replace with your API key
        },
      }) // Replace with your API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Token has expired. Status: ${response.status}.`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  });




  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
    <div id='card-1'>
        <div id='card-1_header'>
          <h2>Groups' payment status</h2>
        </div>
        <div id='card-1_body'>
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Fee</th>
                <th>Days in Week</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Start Date</th>
                <th>Duration</th>
                <th>Months</th>
                <th>Teacher Name</th>
                <th>Subject</th>
                <th>Students</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {data.data.map((item) => (
                <PaymentTable                   
                  id={item.id} 
                  key={item.id}
                  title={item.title}
                  fee ={item.fee}
                  days_in_week={item.days_in_week}
                  start_time={item.start_time}
                  end_time={item.end_time} 
                  start_date={item.start_date}                  
                  teacher_name={item.teacher_name}
                  subject={item.subject}
                  students={item.students}/>
              ))}
            </tbody>
          </table>
        </div>
    </div>
    </>
      
  );
}

export default ShowGroups;
