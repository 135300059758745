import React, { useState, useEffect } from 'react';

import '../../../css/admin/subjects.css';

const url = 'http://api.edupulse.software/api/v1/admin/subjects?limit=100'
export const token_1 = localStorage.getItem('token')

const CreateSubject = () => {
	const [isOpen, setIsOpen] = useState(false);

	const postData = async (formData) => {
		try {
		  const headers = {
			Authorization: `Bearer ${token_1}`,
			'Content-Type': 'application/json',
		  };
  
		  const response = await fetch(url, { 
			method:"POST",
			headers,
			body:JSON.stringify(formData)
		  });
  
		  if (!response.ok) {
			throw new Error('Token is esxperied');
		  }
		  window.location.reload()
		} catch (error) {
			console.log(Error)
		}
	  };

	const togglePopup = () => {
	  setIsOpen(!isOpen);
	};  
	const [formData, setData] = useState({})

	const handleChange = (event) => {	
		const {name, value} = event.target
		setData({...formData,[name]:value})
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		postData(formData);
	  };


	return (
	<div class="box-controls pull-right d-md-flex d-none">
		<btn onClick={togglePopup} href="course.html" class="waves-effect waves-light w-p100 btn btn-primary"><i class="fa fa-plus me-15"></i> Create New Subject</btn>
		{isOpen && (
		<div className="popup rounded10">
		<div className="popup-inner">
			<h2>Let's Create New Subject</h2>
			<form className='just-form' onSubmit={handleSubmit}>
			{/* Your form fields */}
			<label className='form-label'>
				Name:
				<input type="text" name='title' onChange={handleChange}/>
			</label>
			<button type="submit" class="waves-effect waves-light btn btn-primary">Submit</button>
			<button onClick={togglePopup}class="waves-effect waves-light btn btn-danger">Close</button>
			</form>
		</div>
		</div>)}
	</div>
	)
}

export const GetSubjects = () => {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
		try {
			const headers = {
			Authorization: `Bearer ${token_1}`,
			'Content-Type': 'application/json',
			};

			const response = await fetch(url, {
			headers,
			});

			if (!response.ok) {
			throw new Error('Token is esxperied');
			}

			const jsonData = await response.json();
			setData(jsonData);
			setIsLoading(false);
		} catch (error) {
			setError(error);
			setIsLoading(false);
		}
		};

		fetchData();
	}, []);


	return {data:data,error:error,isLoading:isLoading}
}


const Subjects = () => {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
		try {
			const headers = {
			Authorization: `Bearer ${token_1}`,
			'Content-Type': 'application/json',
			};

			const response = await fetch(url, {
			headers,
			});

			if (!response.ok) {
			throw new Error('Token is esxperied');
			}

			const jsonData = await response.json();
			setData(jsonData);
			setIsLoading(false);
		} catch (error) {
			setError(error);
			setIsLoading(false);
		}
		};

		fetchData();
	}, []);

	

	if (isLoading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>Error: {error.message}</div>;
	}

	return (
		<div class="row container mx-auto">
		<div class="box bg-transparent no-shadow mb-30">
			<div class="box-header no-border pb-0">
			<h1 class="box-title">All Subjects</h1>
				<CreateSubject/>
			</div>
		</div>
		<hr />
		{data.data.map((subject, index) => (
			<div class="box mb-15 pull-up">
				<div class="box-body">
					<div class="d-flex align-items-center justify-content-between">
						<div class="d-flex align-items-center">
							<div class="box-body me-15 bg-warning h-45 w-45 	rounded text-center">
								<span class="icon-Book-open fs-24"><span class="path1"></span><span class="path2"></span></span>
							</div>
							<div class="d-flex flex-column w-200">
								<a href={subject.id} class="text-dark hover-primary mb-2 fs-16">{subject.title}</a>
								<span class="text-fade">Nil Yeager, 19 April</span>
							</div>
						</div>
						<a href="course.html">
							<span class="icon-Arrow-right fs-24"><span class="path1"></span><span class="path2"></span></span>
						</a>
					</div>
				</div>
			</div>
			))}
	</div>
	);
	}


export default Subjects