import React, { useState, useEffect } from "react";
import "./student.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import MainSidebar from "../../components/MainSidebar/index";
import Header from "../../components/Header/index";

import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GroupParams = () => {
  const { id } = useParams();
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJlZHVwdWxzZV9hZG1pbiIsImV4cCI6MTcxOTU3OTA3MiwiaXNzIjoiaHR0cHM6Ly9lZHVwdWxzZS51eiIsImxlYXJuaW5nX2NlbnRlcl9pZCI6IjQzNTgyMDBlLTcxN2ItNGY2ZS05YjYyLTMyNzY1NDVkNzU2MiIsIm5hbWUiOiJVbmlCb2FyZCBBZG1pbiIsInJlYWxtIjoidXouZWR1cHVsc2UiLCJyb2xlIjoiYWRtaW4ifQ.rP_hUD8AhOTIelkkbnmB167lBj3uFTJKJdZZmzNcjSQ";
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  // Patch UseStates

  const [title, setTitle] = useState("");
  const [fee, setFee] = useState("");
  const [subjectId, setSubjectId] = useState(0);

  const navigate = useNavigate();

  // Getting Data
  useEffect(() => {
    fetch(`http://api.edupulse.software/api/v1/admin/groups/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. Status: ${response.status}`
          );
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  if (error) {
    console.log("Error:", error.message);
  } else {
    console.log(data);
  }

  if (data == undefined) {
    return <div>Loading ...</div>;
  } else {
    const currentGroup = data.data.find((obj) => obj.id == id);

    const updateGroup = async () => {
      try {
        const updatedData = {
          title: title,
          fee: fee,
          subject_id: subjectId,
        };

        const response = await fetch(
          `http://api.edupulse.software/api/v1/admin/groups/${id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(updatedData),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Network response was not ok. Status: ${response.status}`
          );
        }

        toast("Group updated successfully");

        navigate("/groups");
      } catch (error) {
        setError(error.message);
      }
    };

    return (
      <>
        <Header />
        <MainSidebar />
        <div className="kotta_div">
          <div className="student_inputs">
            <div className="student_params_div">
              <div>
                <p>Title</p>
                <p>Current: {currentGroup.title}</p>
                <input
                  value={title}
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Title"
                />
              </div>
              <div>
                <p>Subject ID</p>
                <p>Current: {currentGroup.subject_id}</p>
                <input
                  value={subjectId}
                  type="text"
                  onChange={(e) => setSubjectId(e.target.value)}
                  placeholder="Subject ID"
                />
              </div>
            </div>
            <div className="student_params_div">
              <div>
                <p>Fee</p>
                <p>Current: {currentGroup.fee}</p>
                <input
                  value={fee}
                  type="text"
                  onChange={(e) => setFee(e.target.value)}
                  placeholder="Fee"
                />
              </div>
            </div>
            <div className="btns_div">
              <Link to="/groups" className="rainbow-hover">
                <button className="rainbow-hover">
                  <span className="sp">Back</span>
                </button>
              </Link>
              <button className="rainbow-hover" onClick={updateGroup}>
                <span className="sp">Update</span>
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
};

export default GroupParams;
