import React, { useEffect, useState } from 'react';

const token = localStorage.getItem("token")

function Payments() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const login=localStorage.getItem("token")

  
  useEffect(() => {
    fetch('http://api.edupulse.software/api/v1/admin/finance/payments',{
        headers: {
          'Authorization': `Bearer ${token}`, // Replace with your API key
        },
      }) // Replace with your API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok. Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  console.log(login)

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div style={{width:"90%", margin: '0 auto', marginTop: '3%'}}>
    <h1 style={{marginBottom: '2%', 
    marginTop: '2%', fontSize: '20px', 
    color: '#a1a4b5', borderColor: '#8790c4'
  
  }}>Finance</h1>
    <div className="card">
            <div style={{margin:"15px 0px 0px 0px"}} className="card-header">
              <h5 className="card-title">Payments of students</h5>
              </div>
            <div className="card-body">
              <table className="table">
        <thead>
          <tr>
            <th style={{width:"35%"}}>Name</th>
            <th className="d-none d-md-table-cell" style={{width:"25%"}}>Phone Number</th>
            <th className="d-none d-md-table-cell" style={{width:"25%"}}>Amount</th>
            <th style={{width:"15%"}}>Month</th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((item) => (
            <tr key={item.id}>
              <td >{item.student.name}</td>
              <td className="d-none d-md-table-cell text-fade">{item.student.phone_number}</td>
              <td className="d-none d-md-table-cell text-fade">{item.amount}</td>
              <td className="d-none d-md-table-cell text-fade">{item.month}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
    </div>
  );
}

export default Payments;