import React, { useEffect, useState } from 'react';

const token = localStorage.getItem("token")

const MonthInfo = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  
  useEffect(() => {
    fetch('http://api.edupulse.software/api/v1/admin/finance/monthly-info?date=2024-06',{
        headers: {
          'Authorization': `Bearer ${token}`, // Replace with your API key
        },
      }) // Replace with your API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok. Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const { month, total_amount, total_paid_amount, total_debt_amount, total_students } = data.data || {};
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Monthly Information for {month}</h2>

      <div style={{marginTop: "3%"}} className="row">
        <div className="col-md-4 mb-3">
          <div className="card shadow-lg border-0 rounded-lg">
          <div className="card-footer bg-light border-0 text-center">
              <h6 style={{marginTop:"0.5%"}} className="text-muted">Total Amount Collected</h6>
              
            </div>
            <div className="card-body bg-primary text-white p-4">
              <h5 className="card-title mb-3">Total Amount</h5>
              <p className="card-text h2">${total_amount}</p>
            </div>
          </div>
        </div>
        

        <div className="col-md-4 mb-3">
          <div className="card shadow-lg border-0 rounded-lg">
          <div className="card-footer bg-light border-0 text-center">
              <h6 style={{marginTop:"0.5%"}} className="text-muted">Debt Payments Received</h6>
            </div>
            <div className="card-body bg-success text-white p-4">
              <h5 className="card-title mb-3">Total Debt Paid</h5>
              <p className="card-text h2">${total_debt_amount}</p>
            </div>
            
          </div>
        </div>

        <div className="col-md-4 mb-3">
          <div className="card shadow-lg border-0 rounded-lg">
          <div className="card-footer bg-light border-0 text-center">
              <h6 style={{marginTop:"0.5%"}} className="text-muted">Active Student Count</h6>
            </div>
            <div className="card-body bg-warning text-white p-4">
              <h5 className="card-title mb-3">Total Students</h5>
              <p className="card-text h2">{total_students}</p>
            </div>
          </div>
        </div>
        <div  className="col-md-4 mb-3">
          <div className="card shadow-lg border-0 rounded-lg">
          <div className="card-footer bg-light border-0 text-center">
              <h6 style={{marginTop:"0.5%"}} className="text-muted">Total Paid Collected</h6>
            </div>
            <div className="card-body bg-danger text-white p-4">
              <h5 className="card-title mb-3">Total Paid Amount</h5>
              <p className="card-text h2">${total_paid_amount}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthInfo;
