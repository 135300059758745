import React from 'react';
import Calendar from '../../components/Calendar'
function MainPage() {
  return (
    <div>
      <h1>Welcome to the Main Page</h1>
      < Calendar />
    </div>
  );
}

export default MainPage;
