import { useNavigate } from 'react-router-dom';

const RedirectButton = ({title,path}) => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(`${path}`);
  }
  return (
    <button id='button_payment' onClick={handleRedirect}>
        {title}
    </button>
  );
};

export default RedirectButton;
// /recipient/payment/showstudents -> ShowStudentPayment link
// /recipient/payment/makepayment -> MakePayment link