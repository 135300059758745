import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import Header from "../../components/Header/index";
import MainSidebar from "../../components/MainSidebar/index";

const Groups = () => {
  const token = localStorage.getItem("token")
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  // Getting Data
  useEffect(() => {
    fetch("http://api.edupulse.software/api/v1/admin/groups?limit=100", {
      headers: {
        Authorization: `Bearer ${token}`, // Replace with your API key
      },
    }) // Replace with your API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. Status: ${response.status}`
          );
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  // Use States

  const [title, setTitle] = useState("");
  const [fee, setFee] = useState("");
  const [subjectId, setSubjectId] = useState(0);
  const [students, setStudents] = useState(0);
  const [subject, setSubject] = useState("");
  const [teacherName, setTeacherName] = useState("");

  // Post

  const postData = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch(
        "http://api.edupulse.software/api/v1/admin/groups?limit=100",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            title: title,
            fee: fee,
            subject_id: subjectId,
            students: students,
            subject: subject,
            teacher_name:teacherName
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Response:", responseData);

      // Optionally, you can update state or perform other actions after successful submission
      // For example, fetch updated data
      // fetchData();
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  if (error) {
    console.log("Error:", error.message);
  }

  if (data.data == undefined) {
    return <div>Loading ...</div>;
  } else {
    return (
      <>
        {/*<Header />
        <MainSidebar />*/}
        <div className="students_hehe">
          <div className="student_inputs hehe">
            <form onSubmit={postData}>
              <div className="student_params_div">
                <div>
                  <p>Title</p>
                  <input
                    value={title}
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Title:"
                  />
                </div>
                <div>
                  <p>Fee</p>
                  <input
                    value={fee}
                    type="number"
                    onChange={(e) => setFee(e.target.value)}
                    placeholder="Fee:"
                  />
                </div>
                <div>
                  <p>Subject ID</p>
                  <input
                    value={subjectId}
                    type="number"
                    onChange={(e) => Number(setSubjectId(e.target.value))}
                    placeholder="Subject ID:"
                  />
                </div>
                <div>
                  <p>Students</p>
                  <input
                    value={students}
                    type="number"
                    onChange={(e) => Number(setStudents(e.target.value))}
                    placeholder="Students:"
                  />
                </div>
                <div>
                  <p>Subject</p>
                  <input
                    value={subject}
                    type="text"
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Subject:"
                  />
                </div>
                <div>
                  <p>Teacher Name</p>
                  <input
                    value={teacherName}
                    type="text"
                    onChange={(e) => setTeacherName(e.target.value)}
                    placeholder="Teacher Name:"
                  />
                </div>
                <div>
                  <button type="submit" className="rainbow-hover upd-btn">
                    Post
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="table_map">
            <tr id="tr2" className="spec">
              <td id="td3">Title</td>
              <td id="td3">Fee</td>
              <td id="td3">Teacher Name</td>
              <td id="td3">Subject</td>
              <td id="td3">Students</td>
            </tr>
            {data.data &&
              data.data.map((group) => (
                <Link to={`/students/${group.id}`} key={group.id}>
                  <tr id="tr2">
                    <td id="td3">{group.title}</td>
                    <td id="td3">{group.fee}</td>
                    <td id="td3">{group.teacherName}</td>
                    <td id="td3">{group.subject}</td>
                    <td id="td3">{group.students}</td>
                  </tr>
                </Link>
              ))}
          </div>
        </div>
      </>
    );
  }
};

export default Groups;
