// src/components/LoginForm.js
import React, { useState } from 'react';
import apiService from './types';
import {jwtDecode} from "jwt-decode";
import '../../css/color_theme.css'
import { useNavigate } from 'react-router-dom';
import '../../css/login.css'


let token, decoded, aud, exp, learning_center_id, name, role, successLogin;
const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate()

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            const response = await apiService.post('/auth/login', { email, password });

            token = "Bearer ";
            token = token + response.data.token;
            decoded = jwtDecode(token);
            aud = decoded.aud;
            exp = decoded.exp;
            learning_center_id = decoded.learning_center_id;
            name = decoded.name;
            role = decoded.role;
            console.log(token);
            successLogin = true;
            navigate('/')
            localStorage.setItem('successLogin', successLogin)
            localStorage.setItem('token', token);
            localStorage.setItem('aud', aud);
            localStorage.setItem('exp', exp);
            localStorage.setItem('learning_center_id', learning_center_id);
            localStorage.setItem('role', role);

            console.log(decoded);

            setError(null);

        } catch (error) {
            console.error('Login error:', error);
            setError('Invalid email or password. Please try again.');
            successLogin = false;
            localStorage.setItem('successLogin', successLogin)
            console.log(successLogin)
        }
    };


    return (
        <>
            <div className="hold-transition dark-skin loginPage  theme-primary bg-img" data-overlay="5">

                <div className="container">
                    <div className="row align-items-center justify-content-md-center h-p100">

                        <div className="col-12">
                            <div className="row justify-content-center g-0">
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="bg-gray-800 rounded10 shadow-lg">
                                        <div className="content-top-agile p-20 pb-0">
                                            <h2 className="text-primary fw-600">Let's Get Started</h2>
                                            <p className="mb-0 text-fade">Sign in to continue to edulearn.</p>
                                        </div>
                                        <div className="p-40">
                                            <form onSubmit={handleLogin} method="post" className='mainLogin'>
                                                <div className="form-group">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text"><i className="text-fade ti-user"></i></span>
                                                        <input type="email"
                                                               placeholder="Email"
                                                               value={email}
                                                               onChange={(e) => setEmail(e.target.value)}
                                                               required/>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text"><i className="text-fade ti-lock"></i></span>
                                                        <input type="password"
                                                               placeholder="Password"
                                                               value={password}
                                                               onChange={(e) => setPassword(e.target.value)}
                                                               required />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        {error && <p className='error-message'>{error}</p>}
                                                        <button type="submit" className="btn btn-primary w-p100">SIGN IN</button>

                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};



export default LoginForm;
