import React from 'react'
import Payments from '../../components/finance/index'

const Finance = () => {
  return (
      <Payments />
    
  )
}

export default Finance