export function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
}

export function formatPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.toString().replace(/\D/g, '');

    if (phoneNumber.startsWith('998')) {
        const countryCode = '+998';
        const areaCode = phoneNumber.slice(3, 5);
        const localNumber = phoneNumber.slice(5);

        const formattedNumber = `${countryCode} ${areaCode} ${localNumber.slice(0, 3)} ${localNumber.slice(3)}`;

        return formattedNumber;
    } else {
        throw new Error("The phone number must start with the Uzbek country code '998'.");
    }
}


export function capitalizeWords(text) {
    return text
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

export function formatUzbekSum(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' UZS';
}

export function getDayAbbreviation(dayNumber) {
    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return days[dayNumber - 1];
}

export function formatTimeRange(startTime, endTime) {
    const startTimeFormatted = startTime.slice(0, 5);
    const endTimeFormatted = endTime.slice(0, 5);
    return `${startTimeFormatted}-${endTimeFormatted}`;
}


export function getFirstLetter(text) {
    if (text && typeof text === 'string') {
        return text.charAt(0);
    }
    return '';
}