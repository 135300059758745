import React, {useEffect, useState } from 'react';
import './Button_styling.css';

const token = localStorage.getItem("token")

const StudentTable = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [payment,setPayment] = useState(100);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await fetch(`http://api.edupulse.software/api/v1/recipient/payment/students?group_id=${1}`, {
                    method: 'GET',
                    headers: headers
                });
                if (!response.ok) {
                    throw new Error(`Token has expired or is invalid{GET}. Status ${response.status}`);
                }
                const result = await response.json();
                setData(result);
                setLoading(false);
                
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        fetchData();
    },[data]);
    // useEffect(() => {
    //     const fetchPost = async()=> {
    //         try{
    //             const headers = {
    //                 'Authorization': `Bearer ${token}`,
    //                 'Content-Type': 'application/json'
    //             };
    //             const body = {
    //                 "group_id": group_id,
    //                 "student_id": student_id,
    //                 "month": '2024-06',
    //                 "amount": 100
    //             };
    //             const response = await fetch(`http://api.edupulse.software/api/v1/recipient/payment/make`, {
    //                 method: 'POST',
    //                 headers: headers,
    //                 body: body
    //             });
    //             if (!response.ok) {
    //                 console.log(group_id);
    //                 console.log(student_id);
    //                 console.log(payment);
    //                 throw new Error(`Token has expired or is invalid{POST}. Status ${response.status}`);
                    
    //             }
    //             setLoading(false);
    //         }
    //         catch(error){
    //             setError(error);
    //             setLoading(false);
    //         };
    //     }
    //     fetchPost();
    // })
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    const handleSubmit = (event)=>{
        event.preventDefault();
        alert(`The sum of ${payment} sums is paid!`)
    }

    if(data.data.length === 0){
        return(
            <>
            <div id='card'>
                <div id='card-header'>
                    <h2>Students' payment status</h2>
                </div>
                <div id='card-body'>
                    <table>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Month</th>
                            </tr>
                        </thead>
                        <tbody> 
                            <tr>                                
                                <td>No student has been found</td>
                            </tr>
                        </tbody>    
                    </table>
                </div>
            </div>
            </>
        );
    }
    else{
        return (
            <>
            <div id='card'>
                <div id='card-header'>
                    <h2>Students' payment status</h2>
                </div>
                <div id='card-body'>
                    <table>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Month</th>
                                <th>Enter amount</th>
                            </tr>
                        </thead>
                        <tbody> 
                            {data.data.map((item) => (
                            <tr key={item.student.id}>
                                <td>{item.status}</td>
                                <td>{item.student.name}</td>
                                <td>{item.amount}</td>
                                <td>{item.month}</td>
                                <td>{<>
                                <form onSubmit={handleSubmit}>
                                    <input id="money-form"
                                    type="text" 
                                    placeholder="0"
                                    onChange={(e) => {
                                        setPayment(e.target.value)
                                        }}/>
                                    <input id="button_payment" type="submit" value='Make Payment'
                                    onClick={ () => {
                                        document.getElementById('money-form').value = "0"
                                    }} />
                                </form>
                                </>
                                    }</td>
                            </tr>
                            ))}
                        </tbody>    
                    </table>
                </div>
            </div>
            </>
        );
    }
};
export default StudentTable;
