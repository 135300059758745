import { useParams } from "react-router-dom";
import MainSidebar from "../../components/MainSidebar/index";
import Header from "../../components/Header/index";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";

import "../../css/vendors_css.css";
import "../../css/style.css";
import "../../css/style_rtl.css";
import "../../css/skin_color.css";
import "../../css/color_theme.css";

const StudentParams = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJlZHVwdWxzZV9hZG1pbiIsImV4cCI6MTcxOTU3OTA3MiwiaXNzIjoiaHR0cHM6Ly9lZHVwdWxzZS51eiIsImxlYXJuaW5nX2NlbnRlcl9pZCI6IjQzNTgyMDBlLTcxN2ItNGY2ZS05YjYyLTMyNzY1NDVkNzU2MiIsIm5hbWUiOiJVbmlCb2FyZCBBZG1pbiIsInJlYWxtIjoidXouZWR1cHVsc2UiLCJyb2xlIjoiYWRtaW4ifQ.rP_hUD8AhOTIelkkbnmB167lBj3uFTJKJdZZmzNcjSQ";

  const [studentList, setStudentList] = useState([]);
  const [error, setError] = useState(null);
  let Student = studentList.find((obj) => obj.id == id);
  const studentIndex = studentList.findIndex((obj) => obj.id === id);
  let studentarr = [];
  studentarr.push(Student);

  // Fetch all students data
  useEffect(() => {
    const fetchAllStudents = async () => {
      try {
        const response = await fetch(
          "http://api.edupulse.software/api/v1/admin/students/?limit=100",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Network response was not ok. Status: ${response.status}`
          );
        }

        const allStudents = await response.json();
        setStudentList(allStudents.data); // Assuming your API response has a `data` field containing an array of students
        console.log("Fetched all students:", allStudents.data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchAllStudents();

    return () => {};
  }, [token]);
  if (error) {
    console.error("Error:", error.message);
  }

  // Delete

  const deleteStudent = async (studentId) => {
    try {
      const response = await fetch(
        `http://api.edupulse.software/api/v1/admin/students/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`
        );
      }
      setStudentList((prevStudentList) =>
        prevStudentList.filter((student) => student.id !== studentId)
      );

      console.log("Deleted student with ID:", studentId);
    } catch (error) {
      setError(error.message);
    }
  };

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
  const [subjects, setSubjects] = useState([]);

  let patch_object = {
    name: name,
    phone_number: phoneNumber,
    secondary_phone_number: secondaryPhoneNumber,
    // subjects:subjects
  };

  // Patch function'

  const updateStudent = async (updatedData) => {
    try {
      const response = await fetch(
        `http://api.edupulse.software/api/v1/admin/students/${id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedData),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Network response was not ok. Status: ${response.status}`
        );
      }

      console.log("Updated student with ID:", id);
    } catch (error) {
      setError(error.message);
    }
  };

  // Render loading state while fetching data
  if (studentList.length === 0) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <Header />
      <MainSidebar />
      <div className="kotta_div">
        <div className="student_inputs">
          <form onSubmit={updateStudent(patch_object)}>
            <div className="student_params_div">
              <div>
                <p>Name</p>
                <p>Current: {Student.name}</p>
                <input
                  value={name}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name:"
                />
              </div>
              <div>
                <p>Phone number</p>
                <p>Current: {Student.phone_number}</p>
                <input
                  value={phoneNumber}
                  type="number"
                  onChange={(e) => Number(setPhoneNumber(e.target.value))}
                  placeholder="Phone number:"
                />
              </div>
              <div>
                <p>Secondary Phone number</p>
                <p>Current: {Student.secondary_phone_number}</p>
                <input
                  value={secondaryPhoneNumber}
                  type="number"
                  onChange={(e) =>
                    Number(setSecondaryPhoneNumber(e.target.value))
                  }
                  placeholder="Secondary Phone number:"
                />
              </div>
              <div id="st_btns">
                <button className="rainbow-hover upd-btn" type="submit">
                  Update
                </button>
                <button className="rainbow-hover del-btn">
                  <span
                    className="sp"
                    onClick={() => {
                      deleteStudent(id); // Pass the studentId parameter
                      navigate("/students");
                    }}
                  >
                    Delete
                  </span>
                </button>
              </div>
            </div>
          </form>

          {/* <form class="form">
            <div class="box-body">
              <h4 class="box-title text-primary mb-0">
                <i class="ti-user me-15"></i> Personal Info
              </h4>
              <hr class="my-15" />
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">First Name</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Last Name</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">E-mail</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Contact Number</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
              </div>
            </div>
            <div class="box-footer">
              <button type="submit" class="btn btn-primary">
                <i class="ti-save-alt"></i> Save
              </button>
            </div>
          </form> */}
        </div>

        <div className="students_group">
          <h1>Assigned Groups</h1>
          <table className="student_table1">
            <thead>
              <tr>
                <th>Group id</th>
                <th>Group title</th>
              </tr>
            </thead>
            <tbody>
              {studentarr.map((student) =>
                student.assigned_groups.map((group) => (
                  <tr key={group.id}>
                    <td>{group.id}</td>
                    <td>{group.title}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default StudentParams;
