import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import axios from 'axios';

export function Calendar(){
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const token = localStorage.getItem('token'); // Replace with your actual token
                const response = await axios.get('http://api.edupulse.software/api/v1/admin/schedule', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log('API Response:', response.data);

                const scheduleData = response.data['0'].schedule;
                console.log('Schedule Data:', scheduleData);

                const formattedEvents = scheduleData.map(event => ({
                    title: event.title,
                    daysOfWeek: event.daysOfWeek, // An array of zero-based day of week integers (0=Sunday)
                    startTime: event.startTime, // an optional time string. like '10:00'
                    endTime: event.endTime, // an optional time string. like '12:00'
                    startRecur: event.startRecur, // start date of the recurring events
                    endRecur: event.endRecur // end date of the recurring events
                }));

                console.log('Formatted Events:', formattedEvents);
                setEvents(formattedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    return (
        <FullCalendar
            plugins={[timeGridPlugin]}
            initialView="timeGridWeek"
            events={events}
        />
    );
};

export default Calendar;
