import {Routes, Route, Router, Navigate} from "react-router-dom";

// Components
import LoginForm from "./components/logIn/index"
import MainPage from "./Pages/Main/MainPage"
import LoginPage from "./Pages/Login/LoginPage"
import Subjects from "./Pages/Admin/Subjects/Subjects";
import Teachers from "./Pages/Admin/Teacher/Teacher";
import PatchTeachers from "./Pages/Admin/Teacher/PatchTeacher";

import Finance from "./Pages/Finance/index"
import MonthInfoPage from "./Pages/MonthInfo/MonthInfo";

import ShowGroups from "./Pages/recipient/payment/ShowGroups";
import ShowStudentPayment from "./Pages/recipient/payment/ShowStudentPayment";

import Students from "./Pages/Students/Students";
import Group from "./Pages/Groups/Groups";
import StudentParams from "./Pages/Students/StudentParams";
import GroupParams from "./Pages/Groups/GroupParams";

import RelatedGroups from "./components/RelatedGroups.jsx/index";
import Groups from "./Pages/Groups/Groups";


const PrivateRoute = ({children}) => {
    const token = localStorage.getItem('token');
    return token ? children : <Navigate to="/login"/>;
};

function App() {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage/>}/>
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <MainPage/>
                    </PrivateRoute>
                }
            />
            <Route path="/" element={<Navigate to="/login"/>}/>

            <Route path="/admin/subjects" element={<Subjects/>}/>
            <Route path="/admin/teachers" element={<Teachers/>}/>
            <Route path="/admin/teachers/:id" element={<PatchTeachers/>}/>

            <Route path="/finance" element={<Finance/>}/>
            <Route path="/monthinfo" element={<MonthInfoPage/>}/>

            <Route path="/payment/showgroups" element={<ShowGroups />} />
            <Route path="/payment/showstudents" element={<ShowStudentPayment/>}/>

            <Route path="/students" element={<Students />} />
            <Route path="/groups" element={<Group />} />
            <Route path="students/:id" element={<StudentParams />} />
            <Route path="groups/:id" element={<GroupParams />} />

            <Route path="/related-groups" element={<RelatedGroups />} />
            <Route path="/related-groups/:id" element={<Groups />} />
        </Routes>
    );
}

export default App;
