import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { token_1 } from '../Subjects/Subjects'; // Adjust import as needed
import { GetSubjects } from '../Subjects/Subjects'; // Adjust import as needed
import '../../../css/admin/teachers.css'; // Adjust CSS import as needed

const url = 'http://api.edupulse.software/api/v1/admin/teachers/';

const PatchTeacher = () => {
    const { id } = useParams();
    const subjectsResponse = GetSubjects(); // Assuming GetSubjects() returns an object with 'data' property containing subjects
    const [subjects, setSubjects] = useState({data:[]}); // Initialize subjects as an empty array
    const [formData, setFormData] = useState({
        name: '',
        position: '',
        is_payment_receivable: false,
        selectedSubjectsId: []
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTeacher = async () => {
            try {
                const response = await fetch(`${url}${id}`, {
                    headers: {
                        Authorization: `Bearer ${token_1}`,
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch teacher data');
                }

                const teacherData = await response.json();

                setFormData({
                    name: teacherData.name,
                    position: teacherData.position,
                    is_payment_receivable: teacherData.is_payment_receivable,
                    selectedSubjectsId: teacherData.subjects.map(subject => subject.id)
                });

                setLoading(false); // Set loading to false once data is fetched
            } catch (error) {
                console.error('Error fetching teacher:', error);
            }
        };

        fetchTeacher();
    }, [id]);

    useEffect(() => {
        if (subjectsResponse && subjectsResponse.data) {
            setSubjects(subjectsResponse.data);
        }
    }, [subjectsResponse]);

    const handleChange = (event) => {
        const { name, value, type, checked, options } = event.target;

        if (type === 'checkbox') {
            setFormData({ ...formData, [name]: checked });
        } else if (type === 'select-multiple') {
            const selectedOptions = Array.from(options).filter(option => option.selected).map(option => Number(option.value));
            setFormData({ ...formData, selectedSubjectsId: selectedOptions });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(formData)
        try {
            const headers = {
                Authorization: `Bearer ${token_1}`,
                'Content-Type': 'application/json',
            };

            const response = await fetch(`${url}${id}`, {
                method: 'PATCH',
                headers,
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Failed to update teacher');
            }

            window.location.reload(); // Reload the page after successful submission

        } catch (error) {
            console.error('Error:', error);
            // Handle error state or display error message to user
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="box mx-auto mt-20" style={{ maxWidth: 800, fontSize: 'large' }}>
            <div className="box-header with-border">
                <h4 className="box-title">Form Sections</h4>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="box-body">
                    <h4 className="mt-0 mb-20">Teacher Info:</h4>
                    <div className="form-group">
                        <label className="form-label">Full Name:</label>
                        <input
                            className="form-control"
                            onChange={handleChange}
                            placeholder="Enter full name"
                            name="name"
                            value={formData.name}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Position:</label>
                        <input
                            className="form-control"
                            onChange={handleChange}
                            placeholder="Enter Position"
                            name="position"
                            value={formData.position}
                        />
                    </div>
                    <hr />
                    <div className="form-group">
                        <div className="c-inputs-stacked">
                            <input
                                onChange={handleChange}
                                name="is_payment_receivable"
                                type="checkbox"
                                id="checkbox_123"
                                checked={formData.is_payment_receivable}
                            />
                            <label htmlFor="checkbox_123" className="me-30">Payment Receivable</label>
                        </div>
                    </div>
                    <hr />
                    <div className="form-group">
                        <label className="form-label">Select Multiple</label>
                        <select
                            multiple
                            onChange={handleChange}
                            className="form-select"
                            value={formData.selectedSubjectsId}
                            name="selectedSubjectsId"
                        >
                            {subjects.data.map((subject, index) => (<option value={subject.id}>{subject.title}</option>))}
                        </select>
                    </div>
                </div>
                <div className="box-footer">
                    <button type="submit" className="btn btn-success pull-right">Save</button>
                </div>
            </form>
        </div>
    );
};

export default PatchTeacher;
